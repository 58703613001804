import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"

import Layout from "../components/layout"
import SEO from "../components/seo"

import CTA from "../components/cta"

import logo from "../images/logo-only.svg"

import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

function PostCard({ date, title, blurb, url, category, image }) {
  const color = image
    ? "bg-black"
    : ["bg-lilac", "bg-blue", "bg-salmon", "bg-emerald-300", "bg-amber-300"][
        Math.floor(Math.random() * 4)
      ]
  return (
    <Link to={url}>
      <div className="max-w-3xl mx-auto p-4 md:p-6 group rounded-xl hover:shadow-2xl hover:bg-neutral-700/50 transition duration-500">
        <p className="text-dark-grey mb-4 font-sans text-sm">
          {date} &mdash; {category}
        </p>
        <div className={color + " rounded-lg aspect-[1280/720] mb-6"}>
          <GatsbyImage image={getImage(image)} className="rounded-lg h-full" />
        </div>
        <div className="text-white group-hover:text-lilac transition font-medium text-xl mb-4">
          {title}
        </div>
        <p className="text-dark-grey mb-6 text-sm">{blurb}</p>
        <div className="font-medium text-blue group-hover:translate-x-1 transition-all">
          Read more →
        </div>
      </div>
    </Link>
  )
}

const Blog = ({ data }) => {
  const posts = data.allMarkdownRemark.nodes
  const contentfulPosts = data.allContentfulBlogPost.nodes

  return (
    <Layout variant="dataPoint">
      <SEO title="Blog" />
      <main>
        <div>
          <div className="border-b border-white/10 text-white">
            <div className="my-6 sm:my-12 lg:my-16 space-y-2 md:space-y-3 container mx-auto px-4 sm:px-6">
              <div className="my-8">
                <Link
                  to="/blog/what-is-a-go-to-market-strategy/"
                  className="bg-black/20 rounded-lg px-2 py-1"
                >
                  New to GTM? Read our{" "}
                  <span className="text-amber">GTM 101 guide.</span>
                </Link>
              </div>
              <h1 className="text-3xl md:text-5xl xl:text-7xl font-bold tracking-tight">
                Go-to-market AI blog
              </h1>
              <p className="text-sm text-white/50 md:text-lg">
                The latest news and resources for automating B2B go-to-market
              </p>
            </div>
          </div>

          <div className="py-12 group container mx-auto px-4 sm:px-6 border-b border-white/10">
            {contentfulPosts.slice(0, 1).map(post => (
              <Link to={"/blog/" + post.slug}>
                <div className="flex flex-col md:flex-row transition gap-12">
                  <div className="max-w-xl flex-1">
                    <div className="uppercase text-blue text-sm">Featured</div>
                    <h2 className="text-white font-medium text-2xl md:text-3xl my-8 group-hover:text-lilac transition">
                      {post.title}
                    </h2>
                    <div className="text-dark-grey text-sm my-8">
                      <p>
                        {post.createdAt} &mdash; {post.blurb.blurb}
                      </p>
                    </div>
                    <div className="font-medium text-blue group-hover:translate-x-1 transition-all">
                      Read more →
                    </div>
                  </div>
                  <GatsbyImage
                    className="flex-1 rounded-xl aspect-video"
                    image={getImage(post.featuredImage)}
                  />
                </div>
              </Link>
            ))}
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 container mx-auto">
            {contentfulPosts.slice(1).map(post => (
              <PostCard
                key={post.contentful_id}
                date={post.createdAt}
                title={post.title}
                blurb={post.blurb.blurb}
                image={post.featuredImage}
                url={"/blog/" + post.slug}
                category={post.category}
              />
            ))}
            {posts.slice(1).map(post => (
              <PostCard
                key={post.id}
                date={post.frontmatter.date}
                title={post.frontmatter.title}
                blurb={post.frontmatter.preview}
                image={post.frontmatter.featuredImage}
                url={post.fields.slug_auto}
                category="Blog"
              />
            ))}
          </div>
        </div>
      </main>
      <div className="container mx-auto">
        <div className="flex items-center gap-6">
          <div className="h-px bg-gray-700 flex-1"></div>
          <img src={logo} className="h-12 mx-auto my-12" />
          <div className="h-px bg-gray-700 flex-1"></div>
        </div>
        <CTA />
      </div>
    </Layout>
  )
}

Blog.propTypes = {
  data: PropTypes.object,
}

export const query = graphql`
  query {
    allMarkdownRemark(
      sort: { fields: frontmatter___date, order: DESC }
      filter: { frontmatter: { category: { eq: "blog" } } }
    ) {
      nodes {
        id
        fields {
          slug_auto
        }
        frontmatter {
          author
          title
          date(formatString: "MMMM DD, YYYY")
          preview
          featuredImage {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        path: gatsbyPath(
          filePath: "/{MarkdownRemark.frontmatter__category}/{MarkdownRemark.frontmatter__slug}"
        )
      }
    }
    allContentfulBlogPost(sort: { fields: createdAt, order: DESC }) {
      nodes {
        contentful_id
        blurb {
          blurb
        }
        author
        category
        title
        createdAt(formatString: "MMMM DD, YYYY")
        slug
        featuredImage {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
    }
  }
`

export default Blog
